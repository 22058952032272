import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostCard from "../components/PostCard"
import { Link } from "gatsby"
import Img from "gatsby-image"
import { PostInfo } from "../fragments/PostInfo";

export default function CategoryTemplate({data, pageContext}) {
  const posts = data.allMdx.nodes;
  
  const currentPage = pageContext.currentPage;
  const numPages = pageContext.numPages;

  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  console.log(pageContext);
  const prevPage = currentPage - 1 === 1 ? `/${pageContext.sourceName}` : `/${pageContext.sourceName}/${(currentPage - 1).toString()}`;
  const nextPage = `/${pageContext.sourceName}/${(currentPage + 1).toString()}`;

  return (
    <Layout section={pageContext.sourceName}>
      <div className="post-list page-category">
        <h1>
          {pageContext.sourceName}
        </h1>
        {posts.map((item, i) => {
          return (
            <PostCard 
              key={i}
              post={item}
            />
        )})}
        <div className="breadcrumbs">
          {!isFirst && (
            <Link className="more-link" to={prevPage} rel="prev">
              ← Later
            </Link>
          )}
          {!isLast && (
            <Link className="more-link" to={nextPage} rel="next">
              Earlier →
            </Link>
          )}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query CategoryQuery($sourceName: String, $skip: Int!, $limit: Int!) {
    allMdx(
      filter: {fields: {sourceName: {eq: $sourceName}}},
      sort:{fields:[frontmatter___date], order:DESC},
      limit: $limit, skip: $skip
      ){
      nodes {
        ...PostInfo
      }
    }
  }
`