import React, { useState, useCallback } from "react"
import Img from "gatsby-image"
import { Link } from "gatsby"

export default function PostCard({post}) {
  const frontmatter = post.frontmatter;

  const body = post.frontmatter.subtitle || post.excerpt
  const image = frontmatter?.imageThumbnail?.childImageSharp?.fluid || 
    frontmatter?.heroImage?.childImageSharp?.fluid;
  const title = frontmatter.title;

  return (
    <div className="post-card">
      <Link to={`/${frontmatter.slug}`}>
        {image && (
          <div className="post-card__hero">
            <Img fluid={image} />
          </div>
        )}
        <div className="post-card__card">
          <div className="post-card__meta">
            <span className="post-card__date">
              {frontmatter.date}
            </span>
            {post.frontmatter.kicker && (
              <span className="post-card__kicker">
                {post.frontmatter.kicker}
              </span>
            )}
          </div>
          <h2>
            {title}
          </h2>
          <h3>
            {body}
          </h3>
          {/* {post.frontmatter.tags && (
            <div className="post-card__tags">
              {post.frontmatter.tags.map((tag, i) => (
              <span key={i}>
                {tag}
              </span>
            ))}
            </div>
          )} */}
        </div>
      </Link>
    </div>
  )
}
